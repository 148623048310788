.content-skills {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 60px;
  box-shadow: inset 0px 5px 20px 0px black;

  background-color: #1d1d1d;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='20' height='20' fill-opacity='0.6' fill='%23181818'/%3E%3C/svg%3E");
  // background-color: $color-back1;
  // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='67' height='67' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%230f0f0f' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}

.content-personal {
  background: $color-back1;
  height: 100%;
  width: 50%;
  border-radius: 28px;
  box-shadow: 3px 5px 5px black;
  margin: 0 20px;
}

.content-personal-title {
  background: $color-back0;
  box-shadow: 3px 5px 5px black;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 50px;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  padding-left: 20px;
  align-items: center;
}

@keyframes zoom {
  0% {
    transform: scale(1);
    box-shadow: 3px 5px 5px black;
  }

  100% {
    transform: scale(1.2);
    box-shadow: 9px 12px 12px black;
  }
}

@keyframes dezoom {
  0% {
    transform: scale(1.2);
    box-shadow: 9px 12px 12px black;
  }

  100% {
    transform: scale(1);
    box-shadow: 3px 5px 5px black;
  }
}

.bubble {
  display: table;
  background: $color-font2;
  color: $color-back1;
  margin: 20px;
  padding: 5px;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  box-shadow: 3px 5px 5px black;
  animation: dezoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    alternate;
  -moz-animation: dezoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    alternate;
  -webkit-animation: dezoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards alternate;
  -o-animation: dezoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    alternate;
}

.bubble:hover {
  animation: zoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite
    alternate;
  -moz-animation: zoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    infinite alternate;
  -webkit-animation: zoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    infinite alternate;
  -o-animation: zoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    infinite alternate;
}

.bubbleRep {
  display: table;
  background: $color-font1;
  color: $color-back1;
  margin: 0 20px;
  padding: 5px;
  margin-left: 60%;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  box-shadow: 3px 5px 5px black;
  animation: dezoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    alternate;
  -moz-animation: dezoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    alternate;
  -webkit-animation: dezoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards alternate;
  -o-animation: dezoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    alternate;
}

.bubbleRep:hover {
  animation: zoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite
    alternate;
  -moz-animation: zoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    infinite alternate;
  -webkit-animation: zoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    infinite alternate;
  -o-animation: zoom 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
    infinite alternate;
}

.content-skill-rate {
  background: $color-back1;
  height: 100%;
  width: 50%;
  border-radius: 28px;
  box-shadow: 3px 5px 5px black;
  margin: 0 20px;
  padding: 20px 20px;
}

.bar-skill {
  width: 100%;
  background: $color-back0;
  height: 3px;
  border-radius: 25px;
}

.bar-skill-fill {
  height: 3px;
  border-radius: 25px;
  cursor: pointer;
}

.skill-element {
  margin: 20px 0;
}

@keyframes bound2 {
  0% {
    transform: scale(1);
  }
  80%,
  100% {
    transform: scale(1.4, 0.6);
  }
}

@keyframes boundout2 {
  0% {
    transform: scale(1.4, 0.6);
  }

  40% {
    transform: scale(0.7, 1.1);
  }
  60% {
    transform: scale(1.2, 0.7);
  }
  80% {
    transform: scale(0.9, 1.05);
  }
  100% {
    transform: scale(1, 1);
  }
}

.skill-title {
  font-size: 4rem;

  span {
    color: #fff;
    margin: 0;
    font-family: $font-title;
    cursor: pointer;
    display: inline-block;
    animation: boundout2 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
      alternate;

    -moz-animation: boundout2 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      forwards alternate;
    -webkit-animation: boundout2 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      forwards alternate;
    -o-animation: boundout2 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      forwards alternate;
  }

  span:hover {
    // color: #08fdd8;
    animation: bound2 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
      infinite alternate;
    -moz-animation: bound2 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
      infinite alternate;
    -webkit-animation: bound2 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      forwards infinite alternate;
    -o-animation: bound2 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
      infinite alternate;
  }
}

.keyword-content {
  display: flex;
  flex-wrap: wrap;
}

.keyword {
  color: $color-font2;
  border: solid $color-font2;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}

.keyword:hover {
  color: white;
  border: solid $color-font2;
}
