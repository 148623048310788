@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Poppins:wght@600&display=swap");

$font-1: "Poppins", sans-serif;
$font-title: "Lato", sans-serif;

$color-font0: #ffffff;
$color-font1: #909096;
// $color-font2: #08fdd8;

$color-font2: #f9b305;

$color-back0: #181818;
$color-back1: #1d1d1d;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  // color: #4b4b4b;
  height: 100%;
  width: 100%;
  font-family: $font-1;
  color: $color-font0;
  font-size: 1rem;
  background-color: $color-back1;
}
.App {
  width: 100%;
  height: 100%;
}

html,
#root,
.ant-layout {
  width: 100%;
  height: 100%;
}
